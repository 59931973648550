:root {
    --green: #35a300;
    --darkgreen: #2e8a01;
    --darkgrey: #222;
}

body {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
}


.app-content a {
    text-decoration: none;
}
.app-content {
    margin-top: 100px;

}

.item1 { 
    grid-area: header;
    
}
.item2 { 
    grid-area: menu; 
    /* border: 1px solid grey; */
    grid-row-start: 2;
    
}
.item3 { 
    grid-area: main; 
    /* border: 1px solid grey; */
}
.item4 { 
    grid-area: right; 
    /* background-color: #35a300; */
    border-radius: 8px;
}
.item5 { grid-area: footer; }

.grid-container {
  display: grid;
  grid-template-areas:
    'menu main main main main main'
    'menu main main main main main'
    'menu footer footer footer footer footer';
  gap: 10px;
  padding: 10px;
  /* border: 1px solid grey; */
}


.grid-container > div {
  text-align: center;
  padding: 20px 0;
  
}

.zbtn {
    font-size: 24px;
    background-color: var(--green);
    border-radius: 8px;
    color: white;
    padding: 6px 10px;
    margin: 4px;
    text-align: left;
    text-decoration: none;
    outline: none;
}
.zbtn:hover {
    background-color: var(--darkgreen);
}

.code-example {
    background-color: var(--darkgrey);
    width:50%;
    border-radius: 8px;
    color: white;
    padding: 10px;
    
}


.pre-func {
    color: var(--green);
    font-size: 20px;
    font-weight: bolder;
}